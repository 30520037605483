.splash-body{
    height: 81vh;
    display: block;
    overflow: hidden;
}
.splash-container{
  height:100%;
} 

/* .splash-parallax-bgimage{


  background-size: cover;
  background-repeat:   no-repeat;
  background-position: bottom 50px right 100px;; 
  background-attachment: fixed;
  
} */

.splash-grid-container {
   position: absolute;
  display: flex;
  flex-direction:column;
  right: 0;

  /* float:right; */
  margin:1vw;
  padding:10vw 1vw 10vw 1vw; 

  min-width:70%;

     /* display: grid;
    
    
    grid-template-areas:
    "h h"
    "s mh"
    "s ml"
    "f f";

    grid-gap: 8px 10px;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 3fr;  */
    
  }
  
    /* .splash-header {
    grid-area: h;
    }

    .splash-sidebar {
    grid-area: s;
    }
*/
    /* .splash-main-content-high {

    }  */

    .splash-main-content-low {

        padding: 1%;
      
    } 


    .splash-footer {
      margin:10% 0 0 0;
      /* grid-area: f;  */
    }  

    .splash-parallax-container{
      position: relative;
      display:"block"; 
      /* object-fit: "cover";  */
      height:120%; 
      overflow: "visible";
      
    }

    .splash-center-horiz{
        display: block;
        
        margin: auto;
        height:auto;
        
      }

      .splash-button-cont{
        margin: 4% auto 4% auto;
      }

    



      @media all and (max-width: 990px) {

        .splash-grid-container {
          position: relative;
          height: 100%;
          
          margin:auto;
          padding: 30vh 0 10vh 0;
         
        }

        
      }

      @media all and (max-height: 720px) {

        .splash-grid-container {
          position: relative;
          height: 100%;
          
          margin: auto;
          padding: 50% 0 10% 0; 
  
        }
        
      } 

      @media all and (max-height: 600px) {

        .splash-grid-container {
          position: relative;
          transform: scale(.7, .7); 
          margin: auto;
          padding: 10% 0 10% 0; 
         
        }
        
      } 



     




      

      
