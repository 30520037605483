:root {
    --main-bg-color: #111111;
    --secondary-bg-color: #141414;
    --tertiary-bg-color: #161616;
    --selection-bg-color: #222222;
    --light-bg-color: #2f2f2f;
    --main-nav-color: #000000;
    --primary-text-color: rgb(231, 229, 229);
    --secondary-text-color: rgb(214, 214, 214);
    --minimized-text-color: rgb(170, 170, 170);
    --minimized-secondary-text-color: rgb(129, 129, 129);
    --emphasied-text-color: white;
  
    --color-termite:  #FC7D35;
    --color-peace: #EB564D;
    --color-plasmana: #FA41E7;
    --color-kidd: #9549EB;
    --color-jackal: #5467FF;


    --theme-color-1: rgb(151, 28, 28);
    --theme-color-2: rgb(212, 146, 4);
    --theme-color-3:rgb(0, 110, 255);

    --theme-color-3-lt:rgb(55, 117, 199);
    --theme-color-1-dk: rgb(105, 20, 20);
    --theme-color-1-dk2: rgb(70, 10, 10);

    
    --color-go: #2fa761;
    --color-stop: #ff3633;


  }* 
  
  {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-family: 'Poppins', sans-serif; 
      color: var(--primary-text-color);
    }