@charset "UTF-8";
html {
  scroll-behavior: smooth;
  max-height: -webkit-fill-available;
  max-width: 2200px;

  width: 100%;
  height: 100%;
  overflow-x: hidden;
  position: absolute;

  /* -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  -o-user-select:none;
  user-select:none; */
}

body {
  background-color: var(--main-bg-color);
  max-height: -webkit-fill-available;
  max-width: 2200px;
  width: 100%;
  margin: 0;
  height: 100%;
}

img {
  pointer-events: none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  -o-user-select:none;
  user-select:none; 
}

h1 {
  color: var(--theme-color-2);
}

ul {
  padding-left: calc(1rem + 1vw);
  margin-bottom: calc(-.25rem + .5vh);
}

.main-header {
  text-align: center;
  top: 0;
  margin: 10% auto 0 auto;
}

.add-shadow {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 5px 1px rgba(0, 0, 0, 0.5);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 5px 1px rgba(0, 0, 0, 0.5);
}

.em {
  font-family: 'Poppins', sans-serif;
  color: var(--emphasied-text-color);
  font-weight: 900;
  font-size: 1.6rem ;
}
.xxl {
  font-size: 3rem ;
}


.xl {
  font-size: 2rem ;
}
.xl-bold {
  font-weight: 900;
  font-size: 2rem ;

}
.norm {
  
  font-size: 1.3rem ;
}
.norm-bold {
  font-weight: 900;
  font-size: 1.3rem ;
}
.s{
  font-size: .9rem ;
}
.xs{
  font-size: .7rem ;
}

.xxs{
  font-size: .6rem ;
}
.xxxs{
  font-size: .5em ;
}

.m-bold{
  font-weight: 900;
  font-size: 1.2rem ;
}
  .opacity-5{
    opacity:.5;
  }
  .opacity-25{
    opacity:.25;
  }
  .opacity-0{
    opacity:0;
  }
  
.navbar {
  background-color: var(--main-nav-color);
}

.textblock {
  font-family: 'Open Sans', sans-serif;
  font-weight: lighter;
  letter-spacing: 2px;
  line-height: calc(1.5rem + 1vw);
  word-spacing: 0.5px;
  max-width: 800px;
  text-align: center;
  text-shadow: 2px 2px 8px #000000;
}


.clean-textblock{
  font-family: 'Open Sans', sans-serif;
  font-weight: lighter;
  text-align: center;
}
.clean-textblocksubheader{
  font-family: 'Open Sans', sans-serif;
  font-weight: 900;
  font-size: 1em;
  text-align: center;
}
.clean-textblockheader{
  font-family: 'Open Sans', sans-serif;
  font-weight: 900;
  font-size: 1.4em;
  text-align: center;
}
.center-text-horiz{
  text-align: center;
}
.black{
  color:var(--tertiary-bg-color);
}
.softgray{
  color:var(--minimized-secondary-text-color);
}
.themered{
  color:var(--theme-color-1);
}
.themeyellow{
  color:var(--theme-color-2);
}
.themeblue{
  color:var(--theme-color-3);
}
.textshadow{
  text-shadow: 2px 2px 8px #000000;
}
.textshadow-small{
  text-shadow: 2px 2px 6px #000000;
}
.stroketext {
  -webkit-text-stroke-width: calc(1em * .05);
  -webkit-text-stroke-color: black;
}
.stroketext-heavy {
  -webkit-text-stroke-width: calc(1em * .1);
  -webkit-text-stroke-color: black;
}

.basic-margin{
  margin:1%;
}

.center-content-vert {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 100vh;
  height: 100%;
  max-height: -webkit-fill-available;
}


.center-content-basic{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
} 


.limit {
  word-wrap: break-word;
}
.zindex-overlay{
  z-index: 5;
}

.element.video {
  margin-bottom: 20vh; /* Adds space below the video element */
}

@media all and (max-width: 990px) {
  .body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .textblock {
    font-size: 1rem ;
    letter-spacing: 1px;
    line-height: 1.6;
    word-spacing: 0.1px;
    max-width: 80vw;
    text-align: justify;
  }
 .center{
  text-align: center;
  }
  .xl-bold{
    font-weight: 900;
    font-size: 1.5rem;
   
  }
  .xl {
    font-size: 1.5rem;
    
  }
  .m-bold{
    font-weight: 900;
    font-size: 1rem;

  }
  .xs {
    font-size: .7rem;
  }

  .em {
    font-weight: 900;
    font-size: 1rem ;
  }
}

.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.3); }


@media all and (max-width: 380px) {
  .textblock {
    font-size: 1rem ;
    letter-spacing: 1px;
    line-height: 1.6;
    word-spacing: 0.1px;
    max-width: 80vw;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
}

.race-stripe {
  /* background-color: rgba(0, 0, 0, 1); */
  border-width: .4vw;
  border-style: solid;
  -o-border-image: linear-gradient(to bottom, var(--theme-color-1), rgba(0, 0, 0, 0)) 1 100%;
     border-image: -webkit-gradient(linear, left top, left bottom, from(var(--theme-color-1)), to(rgba(0, 0, 0, 0))) 1 100%;
     border-image: linear-gradient(to bottom, var(--theme-color-1), rgba(0, 0, 0, 0)) 1 100%;
}

.gradient-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: auto;
  max-width: 80vw;
  max-height: 80vh;
  position: relative;
  padding: 10% 2vh;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.8)), to(black));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), black);
  background-clip: padding-box;
  /* !importanté */
  border: solid 5px transparent;
  /* !importanté */
  border-radius: 1rem;
}

.gradient-box:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -5px;
  /* !importanté */
  border-radius: inherit;
  /* !importanté */
  background: -webkit-gradient(linear, left top, right top, from(var(--theme-color-1)), color-stop(var(--theme-color-2)), to(var(--theme-color-3)));
  background: linear-gradient(to right, var(--theme-color-1), var(--theme-color-2), var(--theme-color-3));
}

.gradient-box-small {
  display: block;
  /* width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  block-size: -webkit-fit-content;
  block-size: -moz-fit-content;
  block-size: fit-content;
  min-width: 200px;
  min-height: 70px; */
  position: relative;
  /* padding: 5% 5% 0 5%; */
  margin: 0px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.8)), to(black));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), black);
  background-clip: padding-box;
  /* !importanté */
  border: solid 1px transparent;
  /* !importanté */
  border-radius: 0.5rem;
}

.gradient-box-small:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -1px;
  /* !importanté */
  border-radius: inherit;
  /* !importanté */
  background: -webkit-gradient(linear, left top, left bottom, from(var(--theme-color-1)), to(var(--theme-color-2)));
  background: linear-gradient(to bottom, var(--theme-color-1), var(--theme-color-2));
}



a{
  color: var(--theme-color-1);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

a:hover{
  color: var(--color-peace);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}


.foot-bold {
  color: var(--primary-text-color);
  font-size: medium;
  font-weight: normal;
  text-decoration: none;
  cursor: pointer;
}
.foot-bold:hover {
  color: var(--secondary-text-color);
  transition: 200ms ease-in;
}

pre {
  margin: 2% auto;
  font-size:xx-small;
  color: var(--minimized-text-color);
  background-color: var(--tertiary-bg-color);
}



