.aws-btn {

    --button-primary-color: rgb(231, 88, 21);
  --button-primary-color-dark: rgb(134, 50, 12);
  --button-primary-color-light: #d8e3f2;
  --button-primary-color-hover: rgb(236, 98, 34);
  --button-primary-color-active: #f8914d;
  --button-primary-border: none;


}

  