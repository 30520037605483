.lost-container{
    display:flex;
    flex-direction: column;
    width: 100vw; 
    height: 40vh;
}

.lost-text{
    margin: 2% auto;
}



