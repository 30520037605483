.navbar{
    font-size:larger;
    font-weight: 900;

}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 80vw;
  }
.navbar-logo{
    margin-top: 1vh;
    margin-bottom: 1vh;
}
  .navbar-menu{
    text-align: center;
    justify-content: end;
    margin-right: 2rem;
  }

  .nav-link{
    /*outline: 1px solid clear;*/
    
  padding: 20px 20px;
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  }
  .navbar-buttons
  {
    margin: 20px 20px;
  }

  .navbar-buttons:hover{
    color: white !important;
    /*outline: 0px solid transparent;*/
  padding: 20px 20px;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  }
.navbar-social{
  display: flex;
  justify-content: center;
  align-items: center;
}
 .navbar-social-buttons:hover{
    color: red !important;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  } 

  .nav-img{
    z-index: 5;
	position:relative;
  
    -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;

      /*Reflection*/
 -webkit-box-reflect: below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(.7, transparent), to(rgba(0,0,0,0.1)));
  }

  .nav-img:hover {  
    cursor: pointer;
    /*Reflection*/
   -webkit-box-reflect: below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(.7, transparent), to(rgba(0,0,0,0.4)));
  
    /*Glow*/
   -webkit-box-shadow: 0px 0px 20px rgba(255,255,255,0.8);
   -moz-box-shadow: 0px 0px 20px rgba(255,255,255,0.8);
   box-shadow: 0px 0px 20px rgba(255,255,255,0.8);
 } 



